/* eslint-disable no-unused-expressions */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable no-restricted-syntax */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import { Text } from '@oneaudi/unified-web-components';
import { renderTextWithFootnotesReferences } from '@oneaudi/feature-app-utils';
import { LocaleServiceV1 } from '@volkswagen-onehub/locale-service';
import { WLTPContainer, MediaDisclaimerContainer, ContentContainer } from './style';
import { VueServiceHelper, WltpProps } from '../../../helper/consumptionAndEmission';
import { ContentContext } from '../../context/ContentContext';

type WLTPComponentsProps = {
  wltpTextValues: string[] | null;
  mediaDisclaimer: string;
  theme: string;
  localeService?: LocaleServiceV1;
};

export const WLTPComponent: React.FC<WLTPComponentsProps> = (props) => {
  const { wltpTextValues, localeService } = props;

  const context: any = React.useContext(ContentContext);
  const { vueFormatterService } = context;

  const [vueTexts, setVueTexts] = React.useState([] as WltpProps[]);

  const { theme, mediaDisclaimer } = props;

  React.useEffect(() => {
    if (wltpTextValues && localeService && vueFormatterService) {
      VueServiceHelper.getConsumptionAndEmission(
        wltpTextValues,
        setVueTexts,
        vueFormatterService,
        localeService,
      );
    }
  }, [wltpTextValues]);

  return (
    <ContentContainer theme={theme}>
      <WLTPContainer theme={theme}>
        {vueTexts &&
          vueTexts?.map(
            (
              {
                formattedConsumption,
                formattedEmission,
                formattedCo2Class,
                formattedDischargedCo2Class,
                formattedDischargedConsumption,
              },
              index,
            ) => (
              // eslint-disable-next-line react/no-array-index-key
              <Text
                tag="p"
                variant={{
                  type: 'copy',
                  order: '2',
                  style: 'normal',
                }}
                key={Math.random()}
              >
                <div>
                  {formattedConsumption && (
                    <span className="sm-wltp-items" tabIndex={-1} key={Math.random()}>
                      {formattedConsumption}
                    </span>
                  )}
                  {formattedEmission && (
                    <span className="sm-wltp-items" tabIndex={-1} key={Math.random()}>
                      {formattedEmission}
                    </span>
                  )}
                  {formattedCo2Class && (
                    <span className="sm-wltp-items" tabIndex={-1} key={Math.random()}>
                      {formattedCo2Class}
                    </span>
                  )}
                  {formattedDischargedConsumption && (
                    <span className="sm-wltp-items" tabIndex={-1} key={Math.random()}>
                      {formattedDischargedConsumption}
                    </span>
                  )}
                  {formattedDischargedCo2Class && (
                    <span className="sm-wltp-items" tabIndex={-1} key={Math.random()}>
                      {formattedDischargedCo2Class}
                    </span>
                  )}
                </div>
              </Text>
            ),
          )}
      </WLTPContainer>
      <MediaDisclaimerContainer
        theme={theme}
        style={{ display: mediaDisclaimer ? 'block' : 'none' }}
      >
        <br />
        {mediaDisclaimer && (
          <Text tag="span" variant={{ type: 'copy', order: '2', style: 'normal' }}>
            {renderTextWithFootnotesReferences(mediaDisclaimer)}
          </Text>
        )}
      </MediaDisclaimerContainer>
    </ContentContainer>
  );
};
