/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
/**
 * This is the starting point of your application.
 * oneAudi OS and Feature Hub Apps will use this file to bootstrap the app.
 */
import React from 'react';
import { Logger } from '@feature-hub/core';
import { GfaServiceConfigProviderV1 } from '@volkswagen-onehub/gfa-service-config-provider';
import {
  ComponentAdditionsV2,
  InternalLinkTrackingDataV2,
  PageUpdateV2,
  TrackingDataV2,
  TrackingServiceV2,
  UserAdditionsV2,
} from '@oneaudi/audi-tracking-service/dist/v2/types';
import { ContentServiceV1 } from '@oneaudi/content-service';
import { VueFormatterServiceInterfaceV1 } from '@oneaudi/vue-formatter-service';
import { LocaleServiceV1 } from '@volkswagen-onehub/locale-service';
import FeatureAppRoot from './components/FeatureAppRoot';
import { TrackingServiceEnvironment } from './trackingUtils/TrackingHelperWrapper';
import { useIsSSR } from './components/useIsSSR';

const emptyTrackingService = {
  featureAppId: '',
  featureAppName: '',
  track: (data: TrackingDataV2) => {},
  enableDebugMode: (featureAppName: string, events: { [key: string]: (args: any) => void }) => {},
  trackInternalNavigation: (data: InternalLinkTrackingDataV2) => {},
  updatePageInfo: (pageUpdate: PageUpdateV2) => {},
  registerImpressionTracking: (element: Element, dataCallback: () => TrackingDataV2) => {},
  updateComponent: (componentUpdate: ComponentAdditionsV2) => {},
  updateUser: (userUpdate: UserAdditionsV2['segment']) => {},
  evaluateLinkType: (targetUrl: string) => '',
  getDatalayer: () => {},
  sanitizeUrl: (url: string) => '',
} as TrackingServiceV2;

interface FeatureAppProps {
  configProvider: GfaServiceConfigProviderV1;
  trackingService?: TrackingServiceV2;
  content?: any;
  contentService?: ContentServiceV1 | undefined;
  loggerService?: Logger;
  vueFormatterService?: VueFormatterServiceInterfaceV1;
  config?: any;
  environment: string;
  localeService: LocaleServiceV1;
}

export type TrackingDemoData = {
  trackingService: TrackingServiceEnvironment;
};

const FeatureApp: React.FC<FeatureAppProps> = ({ ...inherited }: FeatureAppProps) => {
  const { content, loggerService, environment, contentService, localeService } = inherited;

  let trackingService = {} as TrackingServiceV2;
  const isSSR = useIsSSR();
  if (isSSR === false) {
    trackingService = inherited.trackingService || emptyTrackingService;
  }

  return (
    <FeatureAppRoot
      content={content}
      contentService={contentService}
      environment={environment}
      localeService={localeService}
      trackingService={trackingService}
      loggerService={loggerService ?? console}
    />
  );
};

export default FeatureApp;
