/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-redundant-type-constituents */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/**
 * This is the entry point for Feature Hub App integration
 */
import React from 'react';
import { FeatureServices, FeatureAppDefinition } from '@feature-hub/core';
import type { Logger } from '@feature-hub/logger';
import { ContentServiceV1 } from '@oneaudi/content-service';
import { ReactFeatureApp } from '@feature-hub/react';
import { IAuthServiceProviderV1 } from '@oneaudi/audi-auth-service';

import { FootnoteContextProvider } from '@oneaudi/feature-app-utils';
import { TrackingServiceV2 } from '@oneaudi/audi-tracking-service/dist/v2/types';
import {
  AudiFootnoteReferenceServiceInterfaceV3,
  AudiFootnoteRefernceServiceScopeManagerInterfaceV3,
} from '@oneaudi/footnote-reference-service';
import type { VueFormatterServiceInterfaceV1 } from '@oneaudi/vue-formatter-service';
import { EnvConfigServiceV1 } from '@volkswagen-onehub/audi-env-config-service/dist/v1/EnvConfigServiceV1';
import { SerializedStateManagerV1 } from '@feature-hub/serialized-state-manager';
import { AsyncSsrManagerV1 } from '@feature-hub/async-ssr-manager';
// eslint-disable-next-line
import { IPersonalizationServiceV2 } from '@volkswagen-onehub/audi-personalization-service';
import { GfaServiceConfigProviderV1 } from '@volkswagen-onehub/gfa-service-config-provider';
import { LocaleServiceV1 } from '@volkswagen-onehub/locale-service';

import { RenderModeServiceV1 } from '@oneaudi/render-mode-service';
import { UniversalEditorProvider } from '@oneaudi/falcon-tools';
import FeatureApp from './FeatureApp';
import { ContextProvider } from './context';

export interface FeatureServiceDependencies extends FeatureServices {
  readonly 'audi:enConfigService'?: EnvConfigServiceV1;
  readonly 's2:logger'?: Logger;
  readonly 'vw:authService'?: IAuthServiceProviderV1;
  readonly 'audi-content-service'?: ContentServiceV1;
  readonly 'dbad:audi-vue-formatter-service'?: VueFormatterServiceInterfaceV1;
  readonly 'audi-footnote-reference-service'?: AudiFootnoteRefernceServiceScopeManagerInterfaceV3;
  readonly 'locale-service': LocaleServiceV1;
  readonly 'audi-tracking-service'?: TrackingServiceV2;
  readonly 's2:async-ssr-manager'?: AsyncSsrManagerV1;
  readonly 's2:serialized-state-manager'?: SerializedStateManagerV1;
  readonly 'audi-personalization-service'?: IPersonalizationServiceV2;
  readonly 'audi-render-mode-service'?: RenderModeServiceV1;
}

// allow setting ssr mode via URL, so it's set before anything launches
if (typeof window !== 'undefined') {
  const searchParams = new URLSearchParams(window.location.search);
  let ssr = false;
  if (searchParams.has('ssr')) {
    const ssrQueryParam = searchParams.get('ssr') as string;
    ssr = ssrQueryParam === 'true';
    // eslint-disable-next-line no-console
    console.debug(`Query parameter 'ssr' recognized: ${ssr}`);
  }

  // eslint-disable-next-line no-underscore-dangle
  (window as any).__SSR__ = ssr;
}

export type SSRContent = any | undefined;

const featureAppDefinition: FeatureAppDefinition<ReactFeatureApp, FeatureServiceDependencies> = {
  dependencies: {
    featureServices: {
      'audi:envConfigService': '^1.0.0',
      'vw:authService': '^2.0.0',
      // 'notification-display-service': '^1.0.0',
      's2:logger': '^1.0.0',
      'locale-service': '^1.0.0',
      'audi-footnote-reference-service': '^3.0.0',
      'dbad:audi-vue-formatter-service': '^1.0.0',
    },
    externals: {
      react: '^16.13.0 || ^17.0.2 || ^18.x.x',
      'styled-components': '*',
    },
  },

  optionalDependencies: {
    featureServices: {
      'audi-content-service': '^1.0.0',
      's2:logger': '^1.0.0',
      's2:async-ssr-manager': '^1.0.0',
      's2:serialized-state-manager': '^1.0.0',
      'audi-personalization-service': '^1.0.0',
      'audi-render-mode-service': '^1.0.0',
    },
  },

  create: ({ featureServices, featureAppId }) => {
    const authServiceProvider = featureServices['vw:authService'];
    const authService = authServiceProvider?.register('myaudi');
    const envConfigService = featureServices['audi:envConfigService'] as EnvConfigServiceV1;
    const loggerService = featureServices['s2:logger'];
    const contentService = featureServices['audi-content-service'];
    const localeService = featureServices['locale-service'];
    const vueFormatterService = featureServices['dbad:audi-vue-formatter-service'];
    const paramsString = typeof window !== 'undefined' ? window.location.search : '';
    const searchParams = new URLSearchParams(paramsString);

    const providerfootnoteService = featureServices[
      'audi-footnote-reference-service'
    ] as AudiFootnoteRefernceServiceScopeManagerInterfaceV3;

    const configProvider = featureServices[
      'gfa:service-config-provider'
    ] as GfaServiceConfigProviderV1;

    const footnoteReferenceService: AudiFootnoteReferenceServiceInterfaceV3 =
      providerfootnoteService?.getDefaultScopeRefService();

    // SSR
    // --------------------------------------------------------------------
    const asyncSSRManager = featureServices['s2:async-ssr-manager'];
    const serializedStateManager = featureServices['s2:serialized-state-manager'];
    // on the server
    if (asyncSSRManager) {
      asyncSSRManager.scheduleRerender(
        (async () => {
          const content = await contentService?.getContent();
          serializedStateManager?.register(() => JSON.stringify(content));
        })(),
      );
    }

    return {
      render: () => {
        return (
          <UniversalEditorProvider
            contentService={featureServices['audi-content-service']!}
            renderModeService={featureServices['audi-render-mode-service']}
          >
            <FootnoteContextProvider footnoteReferenceService={footnoteReferenceService}>
              <ContextProvider
                featureAppId={featureAppId}
                localeService={localeService}
                vueFormatterService={vueFormatterService!}
                contentService={contentService!}
                footnoteReferenceService={providerfootnoteService}
                authService={authService!}
                envConfigService={envConfigService}
                loggerService={loggerService}
              >
                <div data-fefa-custom-id={footnoteReferenceService?.getConsumerId()}>
                  <FeatureApp
                    environment={searchParams.get('tracking') || 'adapter'}
                    trackingService={featureServices['audi-tracking-service']}
                    loggerService={loggerService}
                    localeService={localeService}
                    configProvider={configProvider}
                  />
                </div>
              </ContextProvider>
            </FootnoteContextProvider>
          </UniversalEditorProvider>
        );
      },
    };
  },
};

export default featureAppDefinition;
